import React, { useContext, useEffect, useState } from "react";
import { Provide } from "../../App";
import { authorizedaxios } from "../../axiosinit";
import { AdminProvider } from "../authenticatedPages/AdminBase";
// import { AdminProvider } from "./AdminBase";
import { collectNewToken } from "../../collectToken";

function NoteModal({ close, clientInfo }) {
  const [note, setNote] = useState('');
  const [date, setDate] = useState('');
  const context = useContext(Provide);
  const admincontext = useContext(AdminProvider);

  const remove = () => {
    authorizedaxios(localStorage.getItem('access_token')).delete(`/admin/remove_notes/client_id=${clientInfo?.client_id}`)
      .then((res) => {
        if (res.status === 200) {
          context.setSuccessMsg(res.data['info']);
          admincontext.alltabledata.find(e => e?.client_id === clientInfo?.client_id)['notes'] = [];
          setNote('')
          setDate('')
          close()
        }
      })
  }

  const updateNote = () => {
    if (clientInfo?.client_id && note) {
      authorizedaxios(localStorage.getItem('access_token')).post(`/admin/update_notes/client_id=${clientInfo?.client_id}`, { note: note, date: new Date().toLocaleDateString() })
        .then((res) => {
          if (res) {
            context.setSuccessMsg(res.data['info'])
            setDate(new Date().toLocaleDateString())
            admincontext.alltabledata.find(e => e?.client_id === clientInfo?.client_id)['notes'][0] = {
              note: note,
              date: new Date().toLocaleDateString(),
              client: clientInfo?.client_id
            };
            close()
          }
        })
        .catch((err) => {
          if (err.response.data['info'] === 'invalid token') {
            context.setErrorMsg('Try Again!')
            collectNewToken(context.admin_id)
          }
        })
    }
  }

  useEffect(() => {
    setNote(clientInfo?.note)
    setDate(clientInfo?.date)
  }, [clientInfo?.note, clientInfo?.date]);

  return (
    <div data-aos="fade-down" id="popup-note-modal" tabIndex={-1} className="hidden form-font fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal h-full justify-center bg-opacity-50 items-center bg-gray-900">
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div id="add-category-modal" className="relative bg-white rounded-lg shadow">
          <div className="px-4 py-5 flex justify-between items-center">
            <div>
              <div className="font-sans text-xl font-semibold text-gray-800">+91 {clientInfo.phone}</div>
              {clientInfo?.note !== '' && <div className="font-sans text-sm font-semibold text-gray-800">Last updated on {date}</div>}
            </div>
            <button className="px-2 py-2">
              <i className="items-center fa-solid fa-xmark text-2xl text-slate-400 hover:text-slate-600" onClick={close} />
            </button>
          </div>
          <hr />
          <div className="px-4 py-2">
            <p className="mb-2 font-medium">Make a note for {clientInfo.fname} {clientInfo.lname}:</p>
            <textarea className="font-[Roboto] px-2 py-1 w-full rounded-sm outline outline-1 outline-orange-500 placeholder:text-slate-500" name="" id="" cols="5" rows="5" placeholder="Leave a note here..." value={note} onChange={(e) => setNote(e.target.value)}></textarea>
          </div>
          <hr />
          {/* <div className="px-4 py-5 flex justify-end space-x-2">
            <button className="px-4 py-2 rounded text-white bg-blue-600 hover:bg-blue-700 text-[13px] sm:text-base" onClick={updateNote}>{clientInfo?.note !== '' ? "Update" : "Save"}</button>
            {clientInfo?.note && <button className="px-4 py-2 rounded text-white bg-red-600 hover:bg-red-700 text-[13px] sm:text-base" onClick={remove}>Remove</button>}
            <button className="px-4 py-2 rounded text-white bg-gray-600 hover:bg-gray-700 text-[13px] sm:text-base" onClick={close}>Close</button>
          </div> */}
          <div className="px-4 py-5 flex justify-between items-center space-x-2">
            <div className="flex gap-3 items-center">
              <button style={{ cursor: (note === '') ? "not-allowed" : "pointer" }} onClick={updateNote}>{clientInfo?.note !== '' ? <i className="fa-solid fa-square-pen text-[2rem] hover:text-blue-700 text-blue-600"></i> : <i className="fa-solid fa-square-plus text-[2.4rem] hover:text-green-700 text-green-600"></i>}</button>
              {clientInfo?.note && <button onClick={remove}><i className="fa-solid fa-trash-can text-[1.8rem] hover:text-red-700 text-red-600"></i></button>}
            </div>
            <div className="">
              <button className="px-4 py-2 rounded text-white bg-gray-600 hover:bg-gray-700 text-[13px] sm:text-base" onClick={close}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoteModal;
