import React, { useEffect, useContext, useRef } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { Provide } from '../../App';

const LoadingToast = () => {
	const context = useContext(Provide);
	const toastId = useRef(null)

	useEffect(() => {
		if (context.is_loading) {
			toastId.current = toast.loading('Please wait...', {
				isLoading: true,
				theme: "dark"
			})
		}
		else {
			toast.update(toastId.current, {
				isLoading: false,
				autoClose: 600
			})
		}
	}, [context.is_loading])

	return (
		<ToastContainer />
	)
}

export default LoadingToast;
