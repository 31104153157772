import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { unauthorizedaxios } from '../../axiosinit';
import SuccessToast from '../toasts/SuccessToast';
import { Provide } from '../../App';
import BrowserTitleName from '../BrowserTitleName';

function ForgotPassword() {
  const navigate = useNavigate();
  const context = useContext(Provide);
  const [emailPage, setEmailPage] = useState(true);
  const [otpPage, setOtpPage] = useState(false);
  const [passwordPage, setPasswordPage] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [passwordISOk, setPasswordISOk] = useState(false);

  const emailSend = () => {
    if (email) {
      context.setIs_loading(true)
      unauthorizedaxios.post('/auth/reset_password', { email: email })
        .then((res) => {
          if (res.status === 200) {
            setEmailPage(false)
            setOtpPage(true)
            setErrorMsg('')
            setSuccessMsg(res.data['info'])
            context.setIs_loading(false)
          }
        })
        .catch((err) => {
          context.setIs_loading(false)
          setErrorMsg(err.response.data['info'])

        })
    }
  }

  const otpSend = () => {
    if (email && otp) {
      context.setIs_loading(true)
      unauthorizedaxios.post('/auth/reset_password', { email: email, otp: otp })
        .then((res) => {
          if (res.status === 200) {
            setOtpPage(false)
            setPasswordPage(true)
            setErrorMsg('')
            setSuccessMsg(res.data['info'])
            context.setIs_loading(false)
          }
        })
        .catch((err) => {
          context.setIs_loading(false)
          setErrorMsg("You have provided an invalid OTP")
        })
    }
  }

  const resetPassword = () => {
    if (email && password && confirmpassword && passwordISOk) {
      //context.setIs_loading(true)
      unauthorizedaxios.post('/auth/reset_password', { email: email, new_password: password })
        .then((res) => {
          if (res.status === 200) {
            //context.setIs_loading(false)
            setPasswordPage(false)
            setEmailPage(true)
            setErrorMsg('')
            setEmail('')
            setOtp('')
            setPassword('')
            setConfirmPassword('')
            context.setSuccessFullyPasswordChange(true)
            context.setSuccessMsg(res.data['info'])
          }
          context.navigate("/login")
        })
        .catch((err) => {
          context.setIs_loading(false)
          setErrorMsg(err.response.data['info'])
        })
    }
  }

  useEffect(() => {
    if (password && password === confirmpassword) {
      setPasswordISOk(true);
      setErrorMsg('')
    } else if (confirmpassword && password !== confirmpassword) {
      setPasswordISOk(false)
      setErrorMsg('password is not matching!')
    } else {
      setPasswordISOk(false)
    }
  }, [confirmpassword]);

  return (
    <>
      <SuccessToast message={successMsg} setMessage={setSuccessMsg} />

      <div className="w-[95%] sm:w-[60%] lg:w-[40%] xl:w-[30%] font-[Roboto] absolute px-10 py-10 rounded-md top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] border shadow-md shadow-slate-400">
        <div className="py-2 mb-6 flex flex-col items-center">
          <div className="mb-6 px-9 py-3 outline outline-4 rounded-full outline-[#00268d]">
            <i class="fa-solid fa-exclamation text-6xl text-[#00268d]"></i>
          </div>
          <div className="font-semibold text-xl text-slate-800">Forgot Password</div>
          <div className="text-[14px] font-medium text-center text-slate-500">
            <span>Enter your email and we'll send you a OTP to reset your password</span>
          </div>
        </div>

        {
          emailPage &&
          <>
            <BrowserTitleName title="Forgot Password" />
            <div className="flex flex-col justify-center items-start">
              <div className="ml-4 absolute">
                <i class="fa-solid fa-envelope text-slate-700"></i>
              </div>
              <input onChange={(e) => setEmail(e.target.value)} value={email} className="py-2 pr-4 pl-10 w-full text-sm rounded outline outline-1 outline-slate-300 text-slate-700 placeholder:text-slate-700 focus:outline-1 focus:shadow-md focus:outline-cyan-500" type="email" id="email" placeholder="Email Address" required />
            </div>
            {errorMsg && <span className="text-[13.5px] text-red-600">{errorMsg}</span>}
          </>
        }

        {
          otpPage &&
          <>
            <BrowserTitleName title="Verify OTP" />
            <div className="flex flex-col justify-center items-start">
              <div className="ml-4 absolute">
                <i class="fa-solid fa-circle-info text-slate-700"></i>
              </div>
              <input onChange={(e) => setOtp(e.target.value)} value={otp} className="py-2 pr-4 pl-10 w-full text-sm rounded outline outline-1 outline-slate-300 text-slate-700 placeholder:text-slate-700 focus:outline-1 focus:shadow-md focus:outline-cyan-500" type="text" id="otp" placeholder="OTP" required />
            </div>
            {errorMsg && <span className="text-[13.5px] text-red-600">{errorMsg}</span>}
          </>
        }

        {
          passwordPage &&
          <div>
            <BrowserTitleName title="Reset Password" />
            <div className="mb-4 flex flex-col justify-center items-start">
              <div className="ml-4 absolute">
                <i className="fa-solid fa-lock text-slate-700" />
              </div>
              <input onChange={(e) => setPassword(e.target.value)} value={password} className="py-2 pr-4 pl-10 w-full text-sm rounded outline outline-1 outline-slate-300 text-slate-700 placeholder:text-slate-700 focus:outline-1 focus:shadow-md focus:outline-cyan-500" type="password" id="password" placeholder="Password" required />
            </div>
            <div className="flex flex-col justify-center items-start">
              <div className="ml-4 absolute">
                <i className="fa-solid fa-lock text-slate-700" />
              </div>
              <input onChange={(e) => setConfirmPassword(e.target.value)} value={confirmpassword} className={password && !passwordISOk ? "py-2 pr-4 pl-10 w-full text-sm rounded outline outline-1 outline-slate-300 text-slate-700 placeholder:text-slate-700 focus:outline-1 focus:shadow-md focus:outline-red-600" : setPasswordISOk && password ? "py-2 pr-4 pl-10 w-full text-sm rounded outline outline-1 outline-slate-300 text-slate-700 placeholder:text-slate-700 focus:outline-1 focus:shadow-md focus:outline-cyan-500" : "py-2 pr-4 pl-10 w-full text-sm rounded outline outline-1 outline-slate-300 text-slate-700 placeholder:text-slate-700 focus:outline-1 focus:shadow-md focus:outline-cyan-500"} type="password" id="cpassword" placeholder="Confirm Password" required />
            </div>
            {
              (password === '') ? <></> : (password && !passwordISOk && password !== '') ? <span className="text-sm text-red-600">Confirm Password didn't match</span> : <span className="text-sm text-cyan-500">Confirm Password matched</span>
            }
          </div>
        }

        <div className="flex flex-col items-center justify-center mt-10">
          {emailPage && <button className="w-full py-1.5 rounded hover:shadow-md hover:shadow-slate-400 focus:shadow-none focus:bg-[#21a700] text-white bg-[#28c700]" disabled={email === ''} onClick={emailSend}>Submit</button>}
          {otpPage && <button className="w-full py-1.5 rounded hover:shadow-md hover:shadow-slate-400 focus:shadow-none focus:bg-[#21a700] text-white bg-[#28c700]" disabled={otp === ''} onClick={otpSend}>Submit</button>}
          {passwordPage && <button disabled={(password && !passwordISOk && password !== '') || password === ''} onClick={resetPassword} className="w-full py-1.5 rounded hover:shadow-md hover:shadow-slate-400 focus:shadow-none focus:bg-[#21a700] text-white bg-[#28c700]">Reset Password</button>}
        </div>

        {
          !passwordPage &&
          <div className="mt-6 text-center text-sm space-x-2 font-medium text-slate-700">
            {
              emailPage &&
              <Link className="space-x-2 cursor-pointer" to='/'>
                <span><i className="fa-solid fa-chevron-left"></i></span>
                <span>Back to Login</span>
              </Link>
            }
            {
              otpPage &&
              <span className="space-x-2 cursor-pointer" onClick={() => {
                emailSend()
              }}>
                <span>Resend OTP</span>
                <span><i className="fa-solid fa-chevron-right"></i></span>
              </span>
            }
          </div>
        }
      </div>
    </>
  );
}

export default ForgotPassword;
