import React, { useContext } from 'react';
import navLogo from "../../assets/TAX-SUVIDHAA.png";
import AddClientModal from '../modal/AddClientModal';
import { AdminProvider } from './AdminBase';

function Navbar(props) {
  const context = useContext(AdminProvider);

  function showAddClientModal() {
    window.document.getElementById("popup-add-client-modal").classList.add("flex");
    window.document.getElementById("popup-add-client-modal").classList.remove("hidden");
  }

  function hideAddClientModal() {
    window.document.getElementById("popup-add-client-modal").classList.add("hidden");
    window.document.getElementById("popup-add-client-modal").classList.remove("flex");
  }

  return (
    <>
      <AddClientModal close={hideAddClientModal} />

      <nav className="py-2 mx-4">
        <div className="relative container mx-auto mt-4 sm:mt-8 px-4 py-2 flex justify-between items-center rounded shadow shadow-slate-600 outline outline-1 outline-slate-400">
          <div title="Logout" className="select-none cursor-pointer" onClick={() => { localStorage.clear(); window.location.reload(); }}>
            <img className="w-28" src={navLogo} alt={"nav-logo"} />
          </div>
          <div className="flex items-center gap-2">
            <div title="Add New Client" className="select-none cursor-pointer" onClick={showAddClientModal}>
              <span className="absolute top-[2px] right-[54px] px-[0.40rem] py-[0.1rem] rounded-full text-xs font-medium text-green-600">
                <i className="fa-solid fa-plus font-bold text-lg"></i>
              </span>
              <div className="px-3 py-1.5 rounded-full shadow shadow-black text-blue-600 bg-white">
                <span className="">
                  <i className="fa-solid fa-user text-xl"></i>
                </span>
              </div>
            </div>
            <div className="select-none">
              {context.alltabledata?.length ? <span className="absolute top-[2px] right-[8px] px-[0.40rem] py-[0.1rem] rounded-full text-xs font-medium text-white bg-red-600">{context.notification?.length}</span> : <></>}
              <div title="Notification" className="px-3 py-1.5 cursor-pointer rounded-full text-white bg-slate-800" onClick={() => props.setShowSideBar(true)}>
                <span className="">
                  <i className="fa-solid fa-bell text-xl"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
