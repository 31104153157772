import { Routes, Route } from 'react-router-dom';
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";

const Base = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/forget-password" element={<ForgotPassword />} />
      </Routes>
    </>
  )
}

export default Base;
