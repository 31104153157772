import React, { useState, useEffect, createContext, useContext } from 'react'
import { Routes, Route } from 'react-router-dom';
import DashBorad from './DashBorad';
import { sendSocketApproval, Socket } from '../../socket';
import { notify } from '../../notify';
import { Provide } from '../../App';
import jwtDecode from 'jwt-decode';
import { collectNewToken } from '../../collectToken';

export const AdminProvider = createContext();

const AdminBase = () => {
	const context = useContext(Provide);
	const [piedata, setPiedata] = useState(null);
	const [linedata, setLinedata] = useState(null);
	const [notification, setNotification] = useState([]);
	const [alltabledata, setAllTabledata] = useState([]);
	const [filteredsearch, setFilteredsearch] = useState([]);

	useEffect(() => {
		if (localStorage.getItem('access_token')) {
			sendSocketApproval();
		}

		Socket.on("to_admin", (data) => {
			notify(data?.first_name+" "+data?.last_name)
			setNotification(p => [data, ...p]);
			setAllTabledata(p=>[...p,data])
			setFilteredsearch(p=>[...p,data])
		});
	}, [Socket, localStorage.getItem('access_token')]);

	  useEffect(() => {
		const id=setInterval(() => {
			if (localStorage.getItem('access_token')&&(jwtDecode(localStorage.getItem('access_token')).exp <= Math.floor(Date.now() / 1000))) {
				try {
				  let stat=collectNewToken(jwtDecode(localStorage.getItem('access_token')).sub)
  
				} catch (error) {
				  
				}
			  }
		}, 5000);

		return ()=>clearInterval(id)
			
	  }, [])


	const data = {
		piedata, setPiedata,
		linedata, setLinedata,
		notification, setNotification,
		alltabledata, setAllTabledata,
		filteredsearch,setFilteredsearch
	};

	return (
		<AdminProvider.Provider value={data}>
			<Routes>
				<Route exact path='/' element={<DashBorad />} />
			</Routes>
		</AdminProvider.Provider>
	)
}

export default AdminBase;
