import React,{useContext} from 'react';
import { authorizedaxios } from '../../axiosinit';
import { Provide } from '../../App';
import { AdminProvider } from '../authenticatedPages/AdminBase';

function InvoiceModal({ close, clientInfo }) {
  const context = useContext(Provide);
  const admincontext = useContext(AdminProvider);

  function hideInvoiceModal() {
    window.document.getElementById("popup-invoice-modal").classList.add("hidden");
    window.document.getElementById("popup-invoice-modal").classList.remove("flex");
  }


  const deleteInvoice=()=>{
    context.setIs_loading(true)
    authorizedaxios(localStorage.getItem('access_token')).delete(`/admin/delete_invoice/client_id=${clientInfo.client_id}`)
    .then(res=>{
      context.setSuccessMsg(res.data['info'])
      admincontext.alltabledata.find(e => e.client_id === clientInfo.client_id)['invoice_details']=[];
      admincontext.filteredsearch.find(e => e.client_id === clientInfo.client_id)['invoice_details']=[];
      hideInvoiceModal();
    })
    .catch(err=>{
      context.setErrorMsg(err.response.data['info'])
    })
    .finally(()=>{
      context.setIs_loading(false)
    })
  }

  return (
    <div>
      <>
        <div id="popup-invoice-modal" tabIndex={-1} className="hidden form-font fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal h-full justify-center bg-opacity-50 items-center bg-gray-900">
          <div className="relative w-full h-full max-w-md md:h-auto">
            <div id="delete-item-modal" className="relative bg-white rounded-lg shadow">
              <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-hide="popup-modal" onClick={close}>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6 text-center">
                <i className="mb-4 fa-solid fa-file-invoice text-5xl text-blue-600"></i>
                {/* <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> */}
                <h3 className="mb-5 text-lg font-normal text-gray-800"><b>{`${clientInfo.fname} ${clientInfo.lname}`}</b>'s Invoice</h3>
                <div className="flex justify-center items-center gap-10">
                  <button onClick={() => {
                    const decision = window.confirm(`Are you sure you want to delete ${clientInfo.fname} ${clientInfo.lname}'s invoice?`);

                    if (decision) {
                      deleteInvoice()
                    } else {
                    }
                  }} data-modal-hide="popup-modal" type="button" className="font-medium rounded-lg text-sm items-center px-4 py-1.5 text-center text-white bg-red-600 hover:bg-red-800">
                    <i className="fa-solid fa-trash-can text-xl"></i>
                  </button>
                  <a href={`https://api.taxsuvidhaa.in/media/${clientInfo.invoice}`} target="_blank" data-modal-hide="popup-modal" type="button" className="text-sm font-medium px-3 py-1 cursor-pointer outline-none rounded-lg border border-gray-400 text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-900">
                    <i className="fa-solid fa-eye text-2xl text-blue-600"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default InvoiceModal;
