import axios from 'axios';

export const unauthorizedaxios = axios.create({
    baseURL: 'https://api.taxsuvidhaa.in/api/v1'
});

export const authorizedaxios = (token) => axios.create({
    baseURL: 'https://api.taxsuvidhaa.in/api/v1',
    headers: {
        Authorization: `Bearer ${token}`
    }
})