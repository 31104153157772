import React, { useState, useContext } from 'react';
import { unauthorizedaxios } from '../../axiosinit';
import { data } from 'autoprefixer';
import { Provide } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import ErrorToast from '../toasts/ErrorToast';
import BrowserTitleName from '../BrowserTitleName';
import { sendSocketApproval } from '../../socket';

function Login() {
  const context = useContext(Provide);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const viewPassword = () => {
    window.document.getElementById("password").type = "text";
    window.document.getElementById("hidden-password").classList.add("hidden");
    window.document.getElementById("open-password").classList.remove("hidden");
  };

  const hidePassword = () => {
    window.document.getElementById("password").type = "password";
    window.document.getElementById("open-password").classList.add("hidden");
    window.document.getElementById("hidden-password").classList.remove("hidden");
  };

  const login = e => {
    e.preventDefault();

    if (email && password) {
      context.setIs_loading(true)
      data = {
        email: email,
        password: password
      };
      unauthorizedaxios.post('/auth/admin/login', data)
        .then((res) => {
          if (res.status === 200) {
            context.setIs_loading(false)
            sendSocketApproval()
            localStorage.setItem('access_token', res.data['access_token']);
            localStorage.setItem('refresh_token', res.data['refresh_token']);
            context.setIs_authenticated(true);
            navigate("/")
            window.location.reload();
          }
        })
        .catch((err) => {
          context.setIs_loading(false)
          console.log(err)
          setErrorMsg(err.response.data['info']);
        })
    } else {
      setErrorMsg('Email or Password is not provided');
    }
  }

  return (
    <>
      <ErrorToast message={errorMsg} setMessage={setErrorMsg} />
      <BrowserTitleName title="Login" />

      <form onSubmit={login} className="w-[95%] sm:w-[60%] lg:w-[40%] xl:w-[30%] font-[Roboto] lg:font-[Poppins] absolute px-10 py-10 rounded top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] duration-200 hover:border hover:shadow-lg hover:shadow-slate-400">
        <div className="py-2 mb-6 flex flex-col items-center">
          <div className="mb-4 px-5 py-5 border-2 rounded-full border-[#a9cc44]">
            <i className="fa-solid fa-key text-4xl text-[#a9cc44]" />
          </div>
          <div className="font-medium text-slate-800">Login to your account</div>
          <div className="text-[14px] text-slate-500">Your credentials</div>
        </div>
        <div className="mb-4 flex flex-col justify-center items-start">
          <div className="ml-4 absolute">
            <i className="fa-solid fa-envelope text-slate-700"></i>
          </div>
          <input className="py-2 pr-4 pl-10 w-full text-sm rounded outline outline-1 outline-slate-300 text-slate-700 placeholder:text-slate-700 focus:outline-1 focus:shadow-md focus:outline-cyan-500" type="email" id="email" placeholder="Email Address" required onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="flex justify-between items-center">
          <div className="ml-4 absolute">
            <i className="fa-solid fa-lock text-slate-700" />
          </div>
          <input className="py-2 pl-10 pr-4 w-full text-sm outline outline-1 outline-slate-300 rounded-l text-slate-700 placeholder:text-slate-700 focus:outline-1 focus:shadow-md focus:outline-cyan-500" type="password" id="password" placeholder="Password" required onChange={(e) => setPassword(e.target.value)} />
          <button type="button" id="hidden-password" className="px-3 py-1.5 outline outline-1 outline-slate-300 rounded-r bg-[#eeeeee]" onClick={viewPassword}>
            <i className="fa-solid fa-eye text-slate-500" />
          </button>
          <button type="button" id="open-password" className="hidden px-3 py-1.5 outline outline-1 outline-slate-300 rounded-r bg-[#eeeeee]" onClick={hidePassword}>
            <i className="fa-solid fa-eye-slash text-slate-500" />
          </button>
        </div>
        <div className="mt-3 flex justify-end">
          <span className="font-[Roboto] text-sm font-medium text-blue-600">
            <Link className="cursor-pointer" to="/forget-password">Forgot Password?</Link>
          </span>
        </div>
        <div className="flex flex-col items-center justify-center mt-10">
          <button type="submit" className="w-full py-1.5 rounded hover:shadow-md hover:shadow-slate-400 focus:shadow-none focus:bg-[#8fb326] text-white bg-[#a9cc44]">Login</button>
        </div>
      </form>
    </>
  );
}

export default Login;
