import React, { useEffect, useState,useContext } from 'react';
import { authorizedaxios } from '../../axiosinit';
import { AdminProvider } from '../authenticatedPages/AdminBase';
import { Provide } from '../../App';


function UpdatePanModal({ close, clientInfo }) {
  const context = useContext(Provide);
  const admincontext = useContext(AdminProvider);

  function hideUpdatePanModal() {
    window.document.getElementById("popup-update-pan-modal").classList.add("hidden");
    window.document.getElementById("popup-update-pan-modal").classList.remove("flex");
  }

  const [client, setClient] = useState({
    pan: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const updatePan = e => {
    e.preventDefault()
    setIsLoading(true)
    authorizedaxios(localStorage.getItem('access_token')).patch(`/admin/update_filing/client_id=${clientInfo.client_id}`, { pan: client.pan })
    .then(res=>{
      admincontext.alltabledata.find(e => e.client_id === clientInfo.client_id)['details'][0]['pan'] = client.pan;
      context.setSuccessMsg(res.data['info'])
      hideUpdatePanModal()
    })
    .catch(err=>{context.setErrorMsg(err.response.data['info'])})
    .finally(()=>{setIsLoading(false)})
  }

  useEffect(() => {
    setClient({ ...client, pan: clientInfo.pan });
  }, [clientInfo]);

  return (
    <div data-aos="fade-down" id="popup-update-pan-modal" tabIndex={-1} className="hidden font-[Roboto] fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal h-full justify-center bg-opacity-50 items-center bg-gray-900">
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div id="add-category-modal" className="relative bg-white rounded-lg shadow">
          <div className="px-4 py-2 flex justify-between items-center">
            <div className="space-y-0.5">
              <div className="text-xl font-semibold text-gray-800">{`${clientInfo.fname} ${clientInfo.lname}`}</div>
              <div className="text-sm text-gray-600">Update PAN</div>
            </div>
            <button className="px-2 py-2">
              <i className="items-center fa-solid fa-xmark text-2xl text-slate-400 hover:text-slate-600" onClick={close} />
            </button>
          </div>
          <hr />
          <div className="px-4 py-2">
            {/* Form */}
            <form onSubmit={updatePan} className="space-y-2">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900">Modify {clientInfo.fname}'s PAN</label>
                <input type="text" pattern='[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}' value={client.pan} onInput={e => {
                  setClient({ ...client, pan: e.target.value.toUpperCase() })
                }} minLength={10} maxLength={10} className="block w-full p-2.5 text-sm rounded-lg border focus:outline-none text-gray-900 border-blue-500" placeholder="AAAPL1234C" required />
              </div>
              <div className="">
                {
                  isLoading ?
                    <button type="button" className="cursor-not-allowed font-[Poppins] italic w-full py-2 rounded opacity-80 text-white bg-blue-900">Updating PAN...</button> :
                    <button type="submit" className="w-full py-2 rounded text-white bg-blue-600 hover:bg-blue-700">Update PAN</button>
                }
              </div>
            </form>
            {/* Form */}
          </div>
          <hr />
          <div className="px-4 py-3 flex justify-end space-x-2">
            <div>
              <button className="px-4 py-2 rounded text-white bg-gray-600 hover:bg-gray-700 text-[13px] sm:text-base" onClick={close}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePanModal;
