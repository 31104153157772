import React, { useContext, useEffect, useRef, useState } from 'react'
import { authorizedaxios } from '../../axiosinit';
import { Provide } from '../../App';
import { AdminProvider } from './AdminBase'
import { motion } from "framer-motion";

const Notification = ({ showSideBar, setShowSideBar }) => {
  let temp = false;
  const ref = useRef(null);
  const appcontext = useContext(Provide);
  const [change, setchange] = useState(false);
  const admincontext = useContext(AdminProvider);

  function removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.client_id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  }

  const updateCallstatus = (client_id) => {
    setchange(true)
    authorizedaxios(localStorage.getItem('access_token')).patch(`/admin/already_called/client_id=${client_id}`)
      .then((res) => {
        if (res.data['info'] === 'updated!') {
          removeObjectWithId(admincontext.notification, client_id)
          admincontext.setNotification(admincontext.notification)
          setchange(false)
          appcontext.setSuccessMsg('Successfully Marked')
          admincontext.alltabledata.find(e => e.client_id === client_id).already_called = true
        }
      })
      .catch((err) => {
        appcontext.setErrorMsg(err.response.data['info'])
      })
  }

  const updateTemp = bool => {
    console.log("Last temp:", temp);
    temp = bool;
  }

  useEffect(() => {
    document.body.addEventListener("click", e => {
      let crossBtn = document.getElementsByClassName("cross-btn")[0];

      if (crossBtn?.contains(e.target)) {
        updateTemp(false);
        setShowSideBar(false);
        console.log("OFF");
      }

      if (ref.current && !ref.current?.contains(e.target)) {
        if (temp) {
          updateTemp(false);
          setShowSideBar(false);
          console.log("OFF");
        } else {
          updateTemp(true);
          console.log("ON");
        }
      }
    });
  }, []);

  return (
    <>
      {
        showSideBar &&
        <div className="font-[Poppins] z-10 w-full h-screen fixed top-0 left-0 bg-opacity-50 bg-slate-800">
          <motion.div ref={ref} initial={{ x: -500, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: .5 }} className=" w-full ms:w-[350px] h-full border-r border-slate-600 bg-white">
            <div className="pl-5 pr-5 py-2 flex justify-between items-center">
              <div className="">
                <span className="font-medium text-lg select-none">Notification</span>
              </div>
              <div className="cross-btn px-[0.5rem] py-[0.1rem] cursor-pointer rounded-full hover:bg-slate-300">
                <i className="fa-solid fa-xmark text-lg text-slate-600"></i>
              </div>
            </div>
            <hr />
            {
              admincontext.notification.length === 0 &&
              <div className="h-full flex items-center justify-center text-xl text-slate-500 select-none">No recent notification</div>
            }
            <div className="select-none h-full overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
              {admincontext.notification &&
                admincontext.notification.map(item =>
                  <div key={item.client_id} className="px-4 py-2 mr-1 flex justify-between items-center space-x-2 cursor-pointer rounded-r hover:bg-slate-200">
                    <div className="px-2 flex items-center space-x-4">
                      {
                        (item.already_called) ?
                          <span><i className="fa-solid fa-circle-check text-xl text-green-500"></i></span> :
                          <span><i className="fa-regular fa-circle text-xl text-green-500"></i></span>
                      }
                      <div className="">
                        <div className="break-all">{item.email}</div>
                        <div className="font-semibold break-all">{item.phone}</div>
                      </div>
                    </div>
                    {
                      !item.already_called &&
                      <button onClick={() => updateCallstatus(item.client_id)} className="self-center px-2 py-0.5 text-sm rounded-full text-white bg-blue-600">Done</button>
                    }
                  </div>
                )
              }
            </div>
          </motion.div>
        </div>
      }
    </>
  );
}

export default Notification;
