import io from "socket.io-client";

export const Socket = io.connect("https://socket.taxsuvidhaa.com");
const room = "taxsuvidha";
export const sendSocketApproval = () => {
    try {
        Socket.on("connect", () => {
            Socket.emit("approve_connection", room)
        })
    } catch (err) {
        console.log(err)
    }
}