import { createContext, useMemo, useState } from "react";
import jwtDecode from "jwt-decode";
import {
  useNavigate
} from 'react-router-dom';
import SuccessToast from "./component/toasts/SuccessToast";
import LoadingToast from "./component/toasts/LoadingToast";
import Base from "./component/unauthorizedPages/Base";
import AdminBase from "./component/authenticatedPages/AdminBase";
import ErrorToast from "./component/toasts/ErrorToast";

export const Provide = createContext();

function App() {
  const navigate = useNavigate();
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [is_authenticated, setIs_authenticated] = useState(false);
  const [is_loading, setIs_loading] = useState(false);

  useMemo(() => {
    try {
      if (!localStorage.getItem('access_token') || jwtDecode(localStorage.getItem('refresh_token')).exp < Math.floor(Date.now() / 1000)) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setIs_authenticated(false)
      }
      else {
        setIs_authenticated(true)
      }
    } catch (error) {

    }

  }, [is_authenticated])


  const data = {
    setSuccessMsg,
    setIs_authenticated,
    navigate,
    setIs_loading, is_loading,
    errorMsg, setErrorMsg
  };

  if (is_authenticated) {
    return (
      <>
        <SuccessToast message={successMsg} setMessage={setSuccessMsg} />
        <ErrorToast message={errorMsg} setMessage={setErrorMsg} />
        <Provide.Provider value={data}>
          <LoadingToast />
          <AdminBase />
        </Provide.Provider>
      </>
    )
  }

  return (
    <>
      <SuccessToast message={successMsg} setMessage={setSuccessMsg} />

      <Provide.Provider value={data}>
        <LoadingToast />
        <Base />
      </Provide.Provider>
    </>
  );
}

export default App;
