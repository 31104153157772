import React, { useState, useContext } from 'react';
import { authorizedaxios } from '../../axiosinit';
import { AdminProvider } from './AdminBase';
import { Provide } from '../../App';
import { capitalizeText } from '../../utils/capitalizeText';

function AddClient() {
  const admincontext = useContext(AdminProvider);
  const appcontext = useContext(Provide);

  const [client, setClient] = useState({
    client_first_name: '',
    client_last_name: '',
    email: '',
    phone: '',
    pan: '',
    itr: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const addClient = e => {
    e.preventDefault();
    setIsLoading(true)
    authorizedaxios(localStorage.getItem('access_token')).post('/auth/manual_clientregister', client)
      .then(res => {
        setIsLoading(false)
        admincontext.setAllTabledata(p => [...p, res.data['data'][0]])
        admincontext.setFilteredsearch(p => [...p, res.data['data'][0]])
        admincontext.setNotification(p => [res.data['data'][0], ...p])
        const message = String(capitalizeText(res.data['info']))
        appcontext.setSuccessMsg(message.substring(0, message.length - 1))
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false)
        appcontext.setErrorMsg(capitalizeText(err.response.data['info']))
      })
  }

  return (
    <div>
      <form onSubmit={addClient} onReset={() => setClient({})} className="space-y-2">
        <div className="flex gap-2">
          <div className="w-full">
            <label className="block mb-2 text-sm font-medium text-gray-900">First Name</label>
            <input type="text" value={client.client_first_name} onChange={e => setClient({ ...client, client_first_name: e.target.value })} className="block w-full p-2.5 text-sm rounded-lg border focus:outline-none text-gray-900 border-blue-500" placeholder="Kapil" required />
          </div>
          <div className="w-full">
            <label className="block mb-2 text-sm font-medium text-gray-900">Last Name</label>
            <input type="text" value={client.client_last_name} onChange={e => setClient({ ...client, client_last_name: e.target.value })} className="block w-full p-2.5 text-sm rounded-lg border focus:outline-none text-gray-900 border-blue-500" placeholder="Mishra" required />
          </div>
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">Email</label>
          <input type="email" value={client.email} onChange={e => setClient({ ...client, email: e.target.value })} className="block w-full p-2.5 text-sm rounded-lg border focus:outline-none text-gray-900 border-blue-500" placeholder="kapilmishra@gmail.com" required />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
          <input type="text" pattern='^[0-9]{10}$' value={client.phone} onInput={e => {
            setClient({ ...client, phone: e.target.value });
          }} minLength={10} maxLength={10} className="block w-full p-2.5 text-sm rounded-lg border focus:outline-none text-gray-900 border-blue-500" placeholder="9073540019" required />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">PAN</label>
          <input type="text" pattern='[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}' value={client.pan} onInput={e => {
            setClient({ ...client, pan: e.target.value.toUpperCase() })
          }} minLength={10} maxLength={10} className="block w-full p-2.5 text-sm rounded-lg border focus:outline-none text-gray-900 border-blue-500" placeholder="AAAPL1234C" required />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">ITR Type (1 - 6)</label>
          <input type="number" value={client.itr} onChange={e => setClient({ ...client, itr: e.target.value })} min={1} max={6} className="block w-full p-2.5 text-sm rounded-lg border focus:outline-none text-gray-900 border-blue-500" placeholder="1/2/3/4/5/6" required />
        </div>
        <div className="flex justify-end">
          <button type="reset" title="Clear Form" className="cursor-pointer">
            <i className="fa-solid fa-broom"></i>
          </button>
        </div>
        <div className="">
          {
            isLoading ?
              <button type="button" className="cursor-not-allowed w-full py-2 rounded opacity-80 text-white bg-blue-900">Adding Client...</button> :
              <button type="submit" className="w-full py-2 rounded text-white bg-blue-600 hover:bg-blue-700">Add Client</button>
          }
        </div>
      </form>
    </div>
  );
}

export default AddClient;
