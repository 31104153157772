import React, { useEffect, useContext, useState } from 'react';
import DataTable from "react-data-table-component";
import { Provide } from '../../App';
import { authorizedaxios } from '../../axiosinit';
import { AdminProvider } from './AdminBase';
import NoteModal from '../modal/NoteModal';
import exportIcon from '../../assets/export.png';
import InvoiceModal from '../modal/InvoiceModal';
import { exportCsv } from 'json2csv-export';
import UpdatePlanModal from '../modal/UpdatePlanModal';
import UpdatePanModal from '../modal/UpdatePanModal';

function ClientInfoTable() {
  const context = useContext(Provide);
  const [search, setSearch] = useState('');
  const admincontext = useContext(AdminProvider);

  const [clientInfo, setClientInfo] = useState({});
  const [itrType, setItrType] = useState('1');
  const [filingDate, setFilingDate] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const [editableClientId, setEditableClientId] = useState('');
  const [isItrTypeSelected, setIsItrTypeSelected] = useState(false);
  const [isFilingDateSelected, setIsFilingDateSelected] = useState(false);

  function showUpdatePanModal() {
    window.document.getElementById("popup-update-pan-modal").classList.add("flex");
    window.document.getElementById("popup-update-pan-modal").classList.remove("hidden");
  }

  function hideUpdatePanModal() {
    window.document.getElementById("popup-update-pan-modal").classList.add("hidden");
    window.document.getElementById("popup-update-pan-modal").classList.remove("flex");
  }

  function showUpdatePlanModal() {
    window.document.getElementById("popup-update-plan-modal").classList.add("flex");
    window.document.getElementById("popup-update-plan-modal").classList.remove("hidden");
  }

  function hideUpdatePlanModal() {
    window.document.getElementById("popup-update-plan-modal").classList.add("hidden");
    window.document.getElementById("popup-update-plan-modal").classList.remove("flex");
  }

  function showInvoiceModal() {
    window.document.getElementById("popup-invoice-modal").classList.add("flex");
    window.document.getElementById("popup-invoice-modal").classList.remove("hidden");
  }

  function hideInvoiceModal() {
    window.document.getElementById("popup-invoice-modal").classList.add("hidden");
    window.document.getElementById("popup-invoice-modal").classList.remove("flex");
  }

  function showNoteModal() {
    window.document.getElementById("popup-note-modal").classList.add("flex");
    window.document.getElementById("popup-note-modal").classList.remove("hidden");
  }

  function hideNoteModal() {
    window.document.getElementById("popup-note-modal").classList.add("hidden");
    window.document.getElementById("popup-note-modal").classList.remove("flex");
  }

  const passClientInfoToNote = (phone, fname, lname, client_id, note = "", date = "") => {
    setClientInfo({ ...clientInfo, phone: phone, fname: fname, lname: lname, client_id: client_id, note: note, date: date });
    showNoteModal();
  }

  const passClientInfoToInvoice = (phone, fname, lname, client_id, invoice) => {
    setClientInfo({ ...clientInfo, phone: phone, fname: fname, lname: lname, client_id: client_id, invoice: invoice });
    showInvoiceModal();
  }

  const passClientInfoToUpdatePlan = (phone, fname, lname, client_id) => {
    setClientInfo({ ...clientInfo, phone: phone, fname: fname, lname: lname, client_id: client_id });
    showUpdatePlanModal();
  }

  const passClientInfoToUpdatePan = (pan, phone, fname, lname, client_id) => {
    setClientInfo({ ...clientInfo, pan: pan, phone: phone, fname: fname, lname: lname, client_id: client_id });
    showUpdatePanModal();
  }

  //const [paginatedtabledata, setPaginatedTabledata] = useState(null)
  //const [totaldatanumber,setTotaldataNumber]=useState(null)
  // const paginatedClientInfo = () => {
  //     authorizedaxios(localStorage.getItem('access_token')).get('/admin/clientAllDetailsPaginated/page=1')
  //     .then((res) => {
  //       if (res) {
  //         setPaginatedTabledata(res.data['data'])
  //         setTotaldataNumber(res.data['total_data'])
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response.data['info'] === 'invalid token') {
  //         collectNewToken(context.admin_id)
  //       }
  //     })
  // }

  const allClientInfo = async () => {
    if (localStorage.getItem('access_token')) {
      const res = await authorizedaxios(localStorage.getItem('access_token')).get('/admin/clientAllDetails')
        .then((res) => {
          console.log(res);

          if (res) {
            admincontext.setAllTabledata(res.data['data']);
            admincontext.setFilteredsearch(res.data['data']);
            res?.data['data'].map(i => {
              if (!i.already_called) {
                admincontext.setNotification(p => [i, ...p])
              }
            })
          }
        })
        .catch((err) => {
          console.log(err);
          allClientInfo();
        })
    }
  }

  const updateItrTypeSelection = client_id => {
    if (client_id && itrType) {
      authorizedaxios(localStorage.getItem('access_token')).patch(`/admin/update_filing/client_id=${client_id}`, { itr_plan: itrType })
        .then((res) => {
          if (res.status === 200) {
            admincontext.alltabledata.find(e => e.client_id === client_id)['details'][0]['itr_plan'] = itrType;
            setIsItrTypeSelected(false);
            setItrType('1');
            context.setSuccessMsg(res.data['info'])
          }
        })
        .catch((err) => {
            context.setErrorMsg(err.response.data['info'])
        })
    }
  }

  const ItrTypeSelection = ({ client_id }) => {
    return (
      <div className="flex items-center space-x-2">
        <select name="nums" id="nums" value={itrType} onChange={e => setItrType(e.target.value)}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
        <span onClick={() => { updateItrTypeSelection(client_id) }}><i className="fa-solid fa-square-check text-lg text-blue-500"></i></span>
      </div>
    );
  }

  const updateFilingDateSelection = client_id => {
    if (client_id && filingDate) {
      authorizedaxios(localStorage.getItem('access_token')).patch(`/admin/update_filing/client_id=${client_id}`, { filing_date: filingDate })
        .then((res) => {
          if (res.status === 200) {
            admincontext.alltabledata.find(e => e.client_id === client_id)['details'][0]['filing_date'] = filingDate
            setIsFilingDateSelected(false)
            setFilingDate('')
            context.setSuccessMsg(res.data['info'])
          }
        })
        .catch((err) => {
          console.log(err)
          setIsFilingDateSelected(false)
          context.setErrorMsg("Filing Date can't be set without PAN & ITR Type")
          context.setErrorMsg(err.response.data['info'])
        })
    }
  }

  const FilingDateSelection = ({ client_id }) => {
    return (
      <div className="flex items-center space-x-2">
        <span onClick={() => { updateFilingDateSelection(client_id) }}><i className="fa-solid fa-square-check text-lg text-blue-500"></i></span>
        <input type="date" value={filingDate} onChange={e => setFilingDate(e.target.value)} />
      </div>
    );
  }

  const sendInvoice = (client, client_id) => {
    let address = window.prompt("Enter client's address");
    let original_price = window.prompt("Enter original price");
    let discount = window.prompt("Enter discount percentage (%)", 0);
    const discounted_price = (original_price - (original_price * (discount / 100)));

    if (!isNaN(discounted_price)) {
      const summary = window.confirm(`Are you sure you want to send invoice to ${client.first_name} ${client.last_name}?\n\nAddress: ${address}\nOriginal Price: Rs. ${original_price}₹\nDiscount: ${discount}% (Discounted Price: Rs. ${discounted_price}₹)`);

      if (summary) {
        context.setIs_loading(true)
        authorizedaxios(localStorage.getItem('access_token')).post(`/admin/create_invoice/client_id=${client_id}`, { client_address: address, original_price: original_price, discount_percentage: discount })
          .then((res) => {
            if (res.status === 200) {
              context.setIs_loading(false)
              admincontext.alltabledata.find(e => e?.client_id === client_id)['invoice_details'][0] = {
                invoice_number: res.data['invoice_number'],
                invoice_date: res.data['invoice_date'],
                invoice_filename: res.data['invoice_filename']
              };
              context.setSuccessMsg(res.data['info'])
            }
          })
          .catch((err) => {
            context.setIs_loading(false)
            context.setErrorMsg(err.response.data['info'])
          })
      }
    } else {
      window.alert("Price format is invalid!");
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      allClientInfo();
      return () => clearTimeout(timeout);
    }, 100);
  }, []);

  useEffect(() => {
    const result = admincontext.alltabledata?.filter(e => {
      const pan = e["details"]?.length && e["details"][0]["pan"].toLowerCase();
      const email = e.email.toLowerCase();
      const phone = e.phone.toLowerCase();
      const first_name = e.first_name.toLowerCase();
      const last_name = e.last_name.toLowerCase();
      const full_name = `${first_name} ${last_name}`;
      return (pan && pan.match(search.toLowerCase())) || full_name.match(search.toLowerCase()) || email.match(search.toLowerCase()) || phone.match(search);
    });

    admincontext.setFilteredsearch(result);
  }, [search]);

  const columns = [
    { name: <b>Full Name</b>, selector: (row) => <span className="whitespace-pre-wrap">{row?.first_name + " " + row?.last_name} {(row['details']?.length) ? <></> : <b><i onClick={() => passClientInfoToUpdatePlan(row?.phone, row?.first_name, row?.last_name, row?.client_id)} title="Add PAN & ITR Type" className="fa-solid fa-pen-to-square cursor-pointer text-green-400"></i></b>}</span> },
    { name: <b>Email</b>, selector: (row) => <span className="whitespace-pre-wrap"><a href={`mailto:${row?.email}`}>{row?.email}</a></span> },
    { name: <b>Phone</b>, selector: (row) => <span className="whitespace-pre-wrap">{row?.phone}</span> },
    { name: <b>Registration date</b>, selector: (row) => row?.registered_date },
    { name: <b>Client Marked</b>, selector: (row) => row?.already_called ? <i className="fa-solid fa-circle-check text-xl text-green-600"></i> : <i className="fa-solid fa-circle-xmark text-xl text-red-600"></i> },
    { name: <b>Call</b>, selector: (row) => <a href={`tel:+91${row?.phone}`}><i className="fa-solid fa-phone text-[#2ab318]"></i></a> },
    { name: <b>Client's Note</b>, selector: (row) => row['notes']?.length ? <i className="fa-solid fa-file-pen cursor-pointer text-lg" onClick={() => passClientInfoToNote(row?.phone, row?.first_name, row?.last_name, row?.client_id, row['notes'][0]['note'], row['notes'][0]['date'])}></i> : <i className="fa-solid fa-plus cursor-pointer" onClick={() => passClientInfoToNote(row?.phone, row?.first_name, row?.last_name, row?.client_id)}></i> },
    { name: <b>PAN</b>, selector: (row) => row['details']?.length ? <span onDoubleClick={() => passClientInfoToUpdatePan(row['details'][0]['pan'], row?.phone, row?.first_name, row?.last_name, row?.client_id)} className="cursor-default whitespace-pre-wrap">{row['details'][0]['pan']}</span> : <i className="fa-solid fa-circle-exclamation text-lg text-red-600"></i> },
    { name: <b>ITR Type</b>, selector: (row) => row['details']?.length ? <span className="select-none" onDoubleClick={() => { setEditableClientId(row?.client_id); setIsItrTypeSelected(true); setIsFilingDateSelected(false); }}>{(isItrTypeSelected && (editableClientId === row?.client_id)) ? <ItrTypeSelection client_id={row?.client_id} /> : <span className="pl-1 text-lg text-blue-800">{row['details'][0]['itr_plan']}</span>}</span> : <i className="fa-solid fa-circle-exclamation text-lg text-red-600"></i> },
    { name: <b>Client Plan Selection Date</b>, selector: (row) => row['details']?.length && row['details'][0]['form_submit_date'] ? row['details'][0]['form_submit_date'] : <i className="fa-solid fa-circle-exclamation text-lg text-red-600"></i> },
    { name: <b>Filing Date</b>, selector: (row) => <span className="select-none" onDoubleClick={() => { setEditableClientId(row?.client_id); setIsFilingDateSelected(true); setIsItrTypeSelected(false); }}>{(isFilingDateSelected && (editableClientId === row?.client_id)) ? <FilingDateSelection client_id={row?.client_id} /> : (row['details']?.length && row['details'][0]['filing_date']) ? row['details'][0]['filing_date'] : <i className="fa-solid fa-calendar-days text-lg text-blue-800"></i>}</span> },
    { name: <b>Invoice</b>, selector: (row) => (row['invoice_details']?.length) ? <span><i onClick={() => passClientInfoToInvoice(row?.phone, row?.first_name, row?.last_name, row?.client_id, row['invoice_details'][0]['invoice_filename'])} title={`Invoice of ${row?.first_name} ${row?.last_name}`} className="fa-solid fa-file-invoice cursor-pointer text-lg"></i></span> : <span onClick={() => sendInvoice(row, row?.client_id)}><i title={`Send Invoice to ${row?.first_name} ${row?.last_name}`} className="fa-solid fa-paper-plane cursor-pointer text-[1.02rem]"></i></span> }
  ];

  const prepareJSON = () => {
    let json = [];

    admincontext.filteredsearch.map(client => {
      json.push({
        "Full Name": `${client.first_name} ${client.last_name}`,
        "Email": client.email,
        "Phone": client.phone,
        "Registration Date": client.registered_date,
        "Client Marked": client.already_called ? "YES" : "NO",
        "PAN": client['details']?.length ? client['details'][0]['pan'] : "N/A",
        "ITR Type": client['details']?.length ? client['details'][0]['itr_plan'] : "N/A",
        "Client Plan Selection Date": client['details']?.length ? client['details'][0]['form_submit_date'] : "N/A",
        "Filing Date": client['details']?.length && client['details'][0]['filing_date'] !== '' ? client['details'][0]['filing_date'] : "N/A"
      });
    })

    return json;
  }

  function downloadXLSX() {
    setIsExporting(true);

    setTimeout(() => {
      exportCsv({
        data: prepareJSON(),
        filename: "taxsuvidhaa"
      });
      setIsExporting(false);
    }, 1000);
  }

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey)

        if (isNotCombinedKey) {
          console.log('Escape key was pressed with out any group keys')
          setIsItrTypeSelected(false)
          setIsFilingDateSelected(false)
        }
      }
    });
  }, []);

 
  return (
    <>
      <NoteModal close={hideNoteModal} clientInfo={clientInfo} />
      <InvoiceModal close={hideInvoiceModal} clientInfo={clientInfo} />
      <UpdatePanModal close={hideUpdatePanModal} clientInfo={clientInfo} />
      <UpdatePlanModal close={hideUpdatePlanModal} clientInfo={clientInfo} />

      <div className="mb-6 sm:mb-10 mx-4">
        <div className="p-2 font-[Roboto] container mx-auto mt-10 rounded-sm shadow shadow-slate-800 outline outline-1 outline-slate-400">
          {
            admincontext.alltabledata &&
            <DataTable
              title={
                <div className="py-4 flex flex-col sm:flex-row sm:justify-between gap-2 sm:gap-0">
                  <h1 className="font-semibold text-3xl text-center sm:text-start">Client's Information</h1>
                  <div className="relative px-6 self-center sm:self-auto" onClick={downloadXLSX}>
                    {
                      isExporting ?
                        <div className="absolute italic text-sm left-4 bottom-[-15px] text-blue-600">Exporting...</div> :
                        <div className="absolute cursor-pointer text-sm bottom-[-15px] hover:text-blue-600">Export</div>
                    }
                    <img title="Export to Excel" className="w-10 cursor-pointer" src={exportIcon} alt="export" />
                  </div>
                </div>
              }
              paginationRowsPerPageOptions={[5, 10, 20, 50]}
              columns={columns}
              data={admincontext.filteredsearch}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="385px"
              selectableRowsHighlight
              highlightOnHover
              className="data-table-scroll-none"
              subHeader
              subHeaderComponent={
                <div className="w-full mr-2">
                  <div className="px-1 py-1 flex justify-end w-full">
                    <input
                      type="text"
                      className="w-full sm:w-[50%] xl:w-[35%] px-2.5 py-1.5 rounded outline outline-1 outline-sky-400 text-slate-800 focus:outline-2 focus:shadow-md placeholder:text-slate-600"
                      name="table-search"
                      id="table-search"
                      placeholder="Search for clients..."
                      value={search}
                      onChange={(event) => setSearch(event.target.value)}
                    />
                  </div>
                </div>
              }
            />
          }
        </div>
      </div>
    </>
  );
}

export default ClientInfoTable;
