import React, { useContext, useEffect, useState } from 'react'
import { AdminProvider } from './AdminBase'
import ClientInfoTable from './ClientInfoTable';
import CustomLineChart from './CustomLineChart';
import CustomPieChart from './CustomPieChart';
import Navbar from './Navbar';
import { authorizedaxios } from '../../axiosinit';
import { Provide } from '../../App';
import Notification from './Notification';
import BrowserTitleName from '../BrowserTitleName';
import { collectNewToken } from '../../collectToken';

const DashBorad = () => {
  const appcontext = useContext(Provide);
  const admincontext = useContext(AdminProvider);
  const [showSideBar, setShowSideBar] = useState(false);

  const getCountOfGraphs = () => {
    if(localStorage.getItem('access_token')){authorizedaxios(localStorage.getItem('access_token')).get('/admin/countings')
      .then((res) => {
        admincontext.setPiedata(res.data['piedata'])
        admincontext.setLinedata(res.data['linedata'])
      })
      .catch((err) => {
        getCountOfGraphs()
      })}
  }

  const getNotifications = () => {
    if(localStorage.getItem('access_token')){authorizedaxios(localStorage.getItem('access_token')).get(`/admin/clientinfo`)
      .then((res) => {
        admincontext.setNotification(res.data['data'])
      })
      .catch((err) => {
        getNotifications()
      })}
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      getCountOfGraphs();
      //getNotifications();
      return () => clearTimeout(timeout);
    }, 300);
    
  }, []);

  return (
    <>
      <BrowserTitleName title="Dashboard" />
      <Navbar setShowSideBar={setShowSideBar} />
      {/* SideBar */}
      <Notification showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {/* SideBar */}
      <div>
        {/* Statistics */}
        <div className="mt-8 mx-4">
          <div className="container mx-auto flex flex-col-reverse items-center gap-4 xl:items-stretch xl:flex-row">
            <CustomLineChart />
            <CustomPieChart />
          </div>
        </div>
        {/* Statistics */}

        <ClientInfoTable />
      </div>
    </>
  )
}

export default DashBorad;
