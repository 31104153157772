import React, { useContext } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { AdminProvider } from './AdminBase';

function CustomPieChart() {
  const RADIAN = Math.PI / 180;
  const COLORS = ['#0088FE', '#00C49F'];
  const context = useContext(AdminProvider);

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="select-none xl:w-[30%] flex justify-center items-center duration-100 rounded shadow shadow-slate-600 outline outline-1 outline-slate-300 hover:shadow-md hover:shadow-slate-600">
      {
        context.piedata &&
        <PieChart width={300} height={300}>
          <Pie
            data={context.piedata}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {context.piedata.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>}
    </div>
  );
}

export default CustomPieChart;
