import React, { useContext } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AdminProvider } from './AdminBase';

function CustomLineChart() {
  const context = useContext(AdminProvider);

  return (
    <ResponsiveContainer className="select-none duration-100 rounded shadow shadow-slate-600 outline outline-1 outline-slate-300 hover:shadow-md hover:shadow-slate-600" aspect={(window.screen.width >= 640) ? 3 : 1.5}>
      {<LineChart data={context.linedata} margin={{ top: 20, bottom: 10, right: 50 }}>
        <CartesianGrid strokeDasharray={"3 3"} />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip contentStyle={{ backgroundColor: "yellow" }} />
        <Legend />
        <Line type="monotone" dataKey="visit" stroke="red" activeDot={{ r: 6 }} />
        <Line type="monotone" dataKey="registrations" stroke="green" activeDot={{ r: 6 }} />
      </LineChart>}
    </ResponsiveContainer >
  );
}

export default CustomLineChart;
