export const notify = (name) => {
    if ("Notification" in window) {
        let ask = window.Notification.requestPermission();
        ask.then(
            (permission) => {
                if (permission === "granted") {
                    const notification = new Notification("TaxSuvidha", {
                        body: `${name} just joined TaxSuvidha`,
                        data: {
                            msg: 555
                        },
                        icon: "./assets/TAX-SUVIDHAA.png",
                        tag: "unique"
                    })
                    notification.onclick = (event) => {
                        event.preventDefault();
                        window.open(`${window.location.origin}`, "_blank");
                    };
                }
            })
    }
}